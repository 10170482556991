import React from "react"
import { graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

import { Helmet } from "react-helmet"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"
import { StaticImage } from "gatsby-plugin-image"

// import PostDetails from "../sections/blog/PostDetails"
// import Comments from "../sections/blog/Comments"
// import Sidebar from "../sections/blog/Sidebar"

const CareerDetails = ({ data }) => {
  const { t } = useTranslation()
  const post = data.contentfulJobOffer
  const id = post.id
  const title = post.title
  const slug = post.slug
  // const publishDate = get(post, "publishDate")
  // const image = get(post, "heroImage")
  // const author = get(post, "author.name")
  // const og_image = post.heroImage.gatsbyImageData.images.fallback.src
  const description = post.body.childMarkdownRemark.excerpt
  const body_html = post.body.childMarkdownRemark.html
  const tags = post.tags
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={tags.join()} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://geerd.ma/careers/${slug}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/geerd/image/upload/v1675528209/geerd/geerd_white_logo_dark_background_cwsft8.png"
        />
      </Helmet>
      <Layout withPattern={false}>
        <div className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 relative py-16 overflow-hidden">
          {/* <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32 relative py-16 overflow-hidden"> */}

          <div className="mx-auto max-w-7xl space-y-8 px-6 lg:px-8">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              {/* <h2 className="text-lg font-semibold text-primary-600">
                Transactions
              </h2> */}
              <h2 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h2>
            </div>

            <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-8">
              <div className="relative z-10">
                <div className="prose prose-primary mx-auto text-gray-500 lg:max-w-none">
                  {ReactHtmlParser(body_html)}
                </div>
                <div className="flex flex-wrap">
                  {tags.map((tag, i) => (
                    <span
                      key={i}
                      className="mt-2 mr-2 inline-flex items-center rounded-full bg-primary-100 px-3 py-0.5 text-sm font-medium text-primary-800"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="mx-auto mt-10 flex flex-wrap max-w-prose text-base lg:max-w-none">
                  <span className="text-xs text-gray-500 leading-normal">
                    {t("apply_careers_instruction")}
                  </span>

                  <div className="rounded-md shadow">
                    <a
                      href={`mailto:talent@geerd.ma?subject=${slug.replace(
                        / /g,
                        "%20"
                      )}`}
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700"
                    >
                      <Trans>Apply</Trans>
                      <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
                <svg
                  className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
                  />
                </svg>
                {title.toLowerCase().includes("marketing") ? (
                  <blockquote className="relative rounded-lg bg-white shadow-lg">
                    <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                      <StaticImage
                        src="https://res.cloudinary.com/geerd/image/upload/v1675431053/corporate/Al_Akhawayn_University_Logo_oybj3i.png"
                        alt="logo-aui"
                        className="w-20"
                        placeholder="blurred"
                      />
                      <div className="relative mt-8 text-lg font-medium text-gray-700">
                        <svg
                          className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative">
                          Working with GEERD has been an amazing experience. As
                          a Junior Marketing intern, I have learned and
                          accomplished so much more than I ever imagined. The
                          team was incredibly supportive and friendly, always
                          willing to answer my questions and guide me along the
                          way. During my time there, I had the chance to work on
                          various tasks that tested my research, analytical and
                          creative skills - all of which helped me gain
                          knowledge and put my business degree to good use.
                          Overall, it has been a great learning opportunity for
                          me, and I am thankful for all the help and guidance
                          from GEERD throughout this journey.
                        </p>
                      </div>
                    </div>
                    <cite className="relative flex items-center rounded-b-lg bg-primary-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
                      <span className="relative flex-none rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform">
                        <StaticImage
                          imgClassName="rounded-full object-cover bg-primary-300"
                          className="w-12 h-12 sm:h-20 sm:w-20"
                          src="https://res.cloudinary.com/geerd/image/upload/v1660310307/corporate/khalil_mata_msoqut.jpg"
                          alt=""
                          placeholder="blurred"
                        />
                      </span>
                      <span className="relative ml-4 font-semibold leading-6 text-primary-300 sm:ml-24 sm:pl-1">
                        <span className="font-semibold text-white sm:inline">
                          Khalil Mata
                        </span>{" "}
                        <span className="sm:inline">
                          Marketing intern at GEERD in 2022{" "}
                        </span>
                      </span>
                    </cite>
                  </blockquote>
                ) : (
                  <blockquote className="relative rounded-lg bg-white shadow-lg">
                    <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                      <StaticImage
                        src="https://res.cloudinary.com/geerd/image/upload/v1675187179/corporate/logo_mjfz79.png"
                        alt="logo-inpt"
                        className="w-20"
                        placeholder="blurred"
                      />
                      <div className="relative mt-8 text-lg font-medium text-gray-700">
                        <svg
                          className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-gray-200"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative">
                          Hello! I am Zineb, and I am currently a 3rd year
                          engineering student at INPT. Last summer, i had the
                          chance to spend two months as a software engineer
                          intern at GEERD and I could not have asked for a
                          better internship experience to build a foundation for
                          my career! I got to learn a lot of technical knowledge
                          and gain valuable hands-on experience through my
                          engagements. I really liked how interns are treated
                          just like other team members and get assigned actual
                          and meaningful tasks in order to successfully
                          integrate into professional life! My biggest takeaway
                          would be the new connections which I had forged with
                          the team members and fellow interns as I had the
                          chance to collaborate with them throughout my
                          internship for different missions.
                        </p>
                      </div>
                    </div>
                    <cite className="relative flex items-center rounded-b-lg bg-primary-600 py-5 px-6 not-italic sm:mt-10 sm:items-start sm:py-5 sm:pl-12 sm:pr-10">
                      <span className="relative flex-none rounded-full border-2 border-white sm:absolute sm:top-0 sm:-translate-y-1/2 sm:transform">
                        <StaticImage
                          imgClassName="rounded-full object-cover bg-primary-300"
                          className="w-12 h-12 sm:h-20 sm:w-20"
                          src="https://res.cloudinary.com/geerd/image/upload/v1675187058/corporate/zineb_zoufir.png"
                          alt=""
                          placeholder="blurred"
                        />
                      </span>
                      <span className="relative ml-4 font-semibold leading-6 text-primary-300 sm:ml-24 sm:pl-1">
                        <span className="font-semibold text-white sm:inline">
                          Zineb Zoufir
                        </span>{" "}
                        <span className="sm:inline">
                          Software developer intern at GEERD in 2022{" "}
                        </span>
                      </span>
                    </cite>
                  </blockquote>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default CareerDetails
export const pageQuery = graphql`
  query JobPostBySlug($slug: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulJobOffer(slug: { eq: $slug }) {
      id
      title
      slug
      publishDate(formatString: "MMMM Do, YYYY")
      location
      body {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      tags
    }
  }
`
